<template>
  <div class="my-input">
    <label v-if="label && value" :for="id" class="my-input__label">
      {{ label }}
      <span v-if="required">
        *
      </span>
    </label>

    <input
      v-if="type !== 'phone'"
      class="my-input__input"
      :class="{
        'my-input__input--filled': value,
        'my-input__input--error': error,
      }"
      :id="id"
      :value="value"
      :placeholder="placeholder + (required ? ' *' : '')"
      :type="type"
      @input="onInput"
    >

    <TheMask
      v-else-if="type === 'phone'"
      class="my-input__input"
      :class="{
        'my-input__input--filled': value,
        'my-input__input--error': error,
      }"
      mask="+38 (0##) ### ## ##"
      masked
      :id="id"
      :value="value"
      :placeholder="placeholder + (required ? ' *' : '')"
      type="tel"
      @input="onInput"
    />

    <small v-if="error && errorMessage" class="my-input__error">
      <img src="@/assets/icons/infocircle.svg" alt="info circle">
      {{ errorMessage }}
    </small>
  </div>
</template>

<script>
import { TheMask } from 'vue-the-mask';

export default {
  name: 'MyInput',

  components: {
    TheMask,
  },

  props: {
    value: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
  },

  methods: {
    onInput(event) {
      let value = this.type === 'phone' ? event : event.target.value;

      if (value && this.type === 'number') {
        value = String(parseInt(value, 10));
      }

      this.$emit('input', value.trim());
    },
  }
};
</script>

<style lang="scss">
@import "../../assets/style/fonts.css";
@import "../../assets/style/vars";
@import "../../assets/style/mixins";

.my-input {
  position: relative;

  &__label {
    position: absolute;
    top: 10px;
    left: 20px;
    font-family: $SB;
    font-size: 13px;
    font-weight: 600;

    span {
      color: $red;
    }
  }

  &__input {
    width: 100%;
    padding: 20px;
    border: 1px solid #d6d6d6;
    color: $grey;
    font-size: 16px;
    outline: none;
    transition: border-color .3s;

    &:hover {
      border-color: $green;
    }

    &:focus {
      border-color: $green;
      outline: 1px solid $green;
    }

    &::placeholder {
      font-size: 16px;
    }

    &--filled {
      padding-top: 26px;
      padding-bottom: 14px;
    }

    &--error,
    &--error:hover,
    &--error:focus {
      border-color: $red;
      outline: 1px solid $red;
    }

    &[type="number"]::-webkit-outer-spin-button,
    &[type="number"]::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &[type="number"] {
      -moz-appearance: textfield;
    }
  }

  &__error {
    display: flex;
    align-items: flex-start;
    gap: 4px;
    font-family: $R;
    color: $red;
    font-size: 14px;
    margin-top: 4px;
  }
}
</style>
