var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"my-input"},[(_vm.label && _vm.value)?_c('label',{staticClass:"my-input__label",attrs:{"for":_vm.id}},[_vm._v(" "+_vm._s(_vm.label)+" "),(_vm.required)?_c('span',[_vm._v(" * ")]):_vm._e()]):_vm._e(),(_vm.type !== 'phone')?_c('input',{staticClass:"my-input__input",class:{
      'my-input__input--filled': _vm.value,
      'my-input__input--error': _vm.error,
    },attrs:{"id":_vm.id,"placeholder":_vm.placeholder + (_vm.required ? ' *' : ''),"type":_vm.type},domProps:{"value":_vm.value},on:{"input":_vm.onInput}}):(_vm.type === 'phone')?_c('TheMask',{staticClass:"my-input__input",class:{
      'my-input__input--filled': _vm.value,
      'my-input__input--error': _vm.error,
    },attrs:{"mask":"+38 (0##) ### ## ##","masked":"","id":_vm.id,"value":_vm.value,"placeholder":_vm.placeholder + (_vm.required ? ' *' : ''),"type":"tel"},on:{"input":_vm.onInput}}):_vm._e(),(_vm.error && _vm.errorMessage)?_c('small',{staticClass:"my-input__error"},[_c('img',{attrs:{"src":require("@/assets/icons/infocircle.svg"),"alt":"info circle"}}),_vm._v(" "+_vm._s(_vm.errorMessage)+" ")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }