<template>
  <div class="my-radio-button">
    <input
      type="radio"
      :id="id"
      :name="group"
      :value="ownValue"
      :checked="ownValue === value"
      class="my-radio-button__input"
      @change="updateValue"
    >
 
    <label :for="id" class="my-radio-button__label">
      <div class="my-radio-button__custom-input" />
      {{ label }}
    </label>
  </div>
</template>

<script>
export default {
  name: 'MyRadioButton',

  props: {
    value: {
      type: [String, Number],
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    group: {
      type: String,
      required: true,
    },
    ownValue: {
      type: [String, Number],
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
  },

  methods: {
    updateValue() {
      this.$emit('input', this.ownValue);
    },
  },
};
</script>

<style lang="scss">
@import "../../assets/style/fonts.css";
@import "../../assets/style/vars";
@import "../../assets/style/mixins";

.my-radio-button {
  &__input {
    display: none;
  }

  &__custom-input {
    height: 24px;
    width: 24px;
    min-width: 24px;
    border: 1px solid #d6d6d6;
    border-radius: 50%;

    transition: border-color 0.3s;

    &:hover {
      border: 2px solid $green;
    }
  }

  &__input:checked + &__label &__custom-input {
    border: 8px solid $green;
  }

  &__label {
    display: flex;
    align-items: center;
    gap: 12px;

    font-family: $R;
    color: $grey;
    font-size: 16px;
    font-weight: 400;
    cursor: pointer;
  }
}
</style>
