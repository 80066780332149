<template>
  <MyModal
    class="questionnaire-thank-you-modal"
    :withCloseButton="false"
    @close="closeThankYouModal"
  >
    <h3>
      Дякуємо!
    </h3>

    <p>
      Ми опрацюємо анкету та надішлемо Вам <br> посилання на оформлений матеріал.
    </p>

    <QuestionnaireButton class="questionnaire-thank-you-modal__button" @click.native="closeThankYouModal">
      Добре
    </QuestionnaireButton>
  </MyModal>
</template>

<script>
import MyModal from '@/components/shared/MyModal.vue';
import QuestionnaireButton from './QuestionnaireButton.vue';

export default {
  name: 'QuestionnaireThankYouModal',

  components: {
    MyModal,
    QuestionnaireButton,
  },

  methods: {
    closeThankYouModal() {
      this.$emit('close');
    },
  }
};
</script>

<style lang="scss">
.questionnaire-thank-you-modal {
  p {
    font-size: 14px;
    text-align: center;
    margin-bottom: 18px;
  }

  &__button {
    width: 240px;
    margin: 0 auto;
  }
}
</style>
