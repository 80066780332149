<template>
  <div class="my-modal-backdrop" @click.self="closeModal">
    <div class="my-modal">
      <button v-if="withCloseButton" class="my-modal__close-button" @click="closeModal"></button>
      <div class="my-modal__body">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MyModal',

  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    withCloseButton: {
      type: Boolean,
      default: true,
    },
  },

  mounted() {
    document.body.style.overflow = 'hidden';
  },

  beforeDestroy() {
    document.body.style.overflow = '';
  },

  methods: {
    closeModal() {
      this.$emit('close');
    },
  },
};
</script>


<style lang="scss">
@import "../../assets/style/fonts.css";
@import "../../assets/style/vars";
@import "../../assets/style/mixins";

.my-modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #0007;
}

.my-modal {
  position: relative;

  max-height: 90vh;
  max-width: 820px;
  min-width: 320px;

  padding: 64px;
  background-color: #fff;
  border-radius: 30px;

  @include phone {
    padding: 32px 24px;
  }

  &__close-button {
    position: absolute;
    top: 30px;
    right: 30px;
    height: 40px;
    width: 40px;
    background-image: url('../../assets/icons/cross.svg');
    background-size: 30px;
    background-position: center;
    background-repeat: no-repeat;
    background-color: $green;
    border: none;
    border-radius: 50%;
    cursor: pointer;

    @include phone {
      top: 20px;
      right: 20px;
      height: 32px;
      width: 32px;
      background-size: 24px;
    }
  }

  &__body {
    max-height: calc(90vh - 128px);
    overflow: auto;
    font-family: $R;
    color: $grey;

    @include phone {
      max-height: calc(90vh - 88px);
    }

    h3 {
      font-family: $B;
      font-size: 38px;
      line-height: 46px;
      margin-bottom: 20px;
      text-align: center;

      @include phone {
        font-size: 20px;
        line-height: 24px;
        margin: 0 30px 20px;
      }
    }
    
    p {
      line-height: 22px !important;
    }

    strong {
      font-family: $EB;
    }
  }
}
</style>
