<template>
  <MyModal class="questionnaire-agreement-data-processing-modal" @close="$emit('close')">
    <h3>
      Згода на обробку <br> персональних даних
    </h3>

    <p>
      Дійсним підтверджую достовірність наданих мною даних та надаю свою згоду на обробку моїх персональних даних, вказаних в цій анкеті чи повідомлених мною Тева пізніше, з метою обміну досвідом з колегами, лікарями, фахівцями у галузі медицини та фармації та/або отримання відповідей на запитання щодо застосування ЛЗ компанії Тева в описаному випадку, який виник в минулому, з подальшим можливим розміщенням на цьому веб порталі. Також підтверджую свою згоду з
      <a
        href="https://www.tevapharm.com/our-company/corporate-governance/data-privacy/"
        class="questionnaire-agreement-data-processing-modal__link"
        target="_blank"
      >
        політикою конфіденційності
      </a>
      та те, що мені відомо про мої права суб’єкта персональних даних, передбачені статтею 8 Закону України «Про захист персональних даних». Крім того, мені відомо про право вимагати видалення або зміни моїх контактних даних, розміщених на веб порталі, шляхом направлення запиту за адресою чи електронною поштою, зазначеними нижче.
    </p>
    <br>
    <p class="questionnaire-agreement-data-processing-modal__owner">
      <strong>
        Власник персональних даних:
      </strong>
      <br>
      ТОВАРИСТВО З ОБМЕЖЕНОЮ ВІДПОВІДАЛЬНІСТЮ «ТЕВА УКРАЇНА»
      <br>
      Ідентифікаційний код юридичної особи: 34770471
      <br>
      Україна, 02152, місто Київ, проспект Павла Тичини, 1-В, поверх 9, БЦ «Silver Breeze»
      <br>
      Електронна адреса: 
      <a
        class="questionnaire-agreement-data-processing-modal__link questionnaire-agreement-data-processing-modal__link--hidden"
        href="mailto:Medinfo@teva.ua"
      >
        Medinfo@teva.ua
      </a>
      <br>
      Телефон: 
      <a
        class="questionnaire-agreement-data-processing-modal__link questionnaire-agreement-data-processing-modal__link--hidden"
        href="tel:+380445947080"
      >
        +380 44 594 70 80
      </a>
    </p>
  </MyModal>
</template>

<script>
import MyModal from '@/components/shared/MyModal.vue';

export default {
  name: 'QuestionnaireMoreAboutResourceModal',

  components: {
    MyModal,
  },
};
</script>

<style lang="scss">
@import "../../assets/style/fonts.css";
@import "../../assets/style/vars";
@import "../../assets/style/mixins";

.questionnaire-agreement-data-processing-modal {
  &__link {
    color: $green;
    cursor: pointer;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }

    &--hidden {
      color: $grey;
      text-decoration: none;
    }
  }

  &__owner {
    strong {
      font-family: $SB;
    }

    font-size: 14px;
  }
}
</style>
