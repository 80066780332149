<template>
  <MyModal class="questionnaire-more-about-resource-modal" @close="$emit('close')">
    <h3>
      Детальніше про ресурс
    </h3>

    <p>
      <strong>Артеріальна гіпертензія</strong> — це найпоширеніший серцево-судинний розлад у світі, який, згідно з даними ВООЗ, вражає 1,28 млрд світового дорослого населення у віці 30–79 років. Підвищення рівня АТ нерозривно пов’язане з ризиком виникнення інсульту, ішемічної хвороби серця (ІХС), СН і розвитку та прогресування ХХН. Це стосується всіх вікових та етнічних груп*.
    </p>
    <br>
    <p>
      Враховуючи поширеність серцево-судинних захворювань, у практиці сімейного лікаря, ймовірно, доволі часто виникають ситуації, які вимагають обміну досвідом з колегами, лікарями, фахівцями у галузі медицини, щодо чинних рекомендацій (клінічні настанови, протоколи, міжнародні рекомендації) по веденню пацієнтів з серцево-судинними захворюванням.
    </p>
    <br>
    <p>
      Компанія Тева в Україні створила цей ресурс, щоб допомогти з джерелами інформації, які регулюють алгоритми надання медичної допомоги пацієнтам з серцево-судинними захворюваннями, на базі практичних клінічних випадків, які виникли у сімейного лікаря в минулому, та для обміну знаннями щодо лікування серцево-судинних захворювань.
    </p>

    <span class="questionnaire-more-about-resource-modal__footnote">
      *Артеріальна гіпертензія, клінічна настанова, заснована на доказах (2024)
    </span>
  </MyModal>
</template>

<script>
import MyModal from '@/components/shared/MyModal.vue';

export default {
  name: 'QuestionnaireMoreAboutResourceModal',

  components: {
    MyModal,
  },
};
</script>

<style lang="scss">
@import "../../assets/style/fonts.css";
@import "../../assets/style/vars";
@import "../../assets/style/mixins";

.questionnaire-more-about-resource-modal {
  &__footnote {
    display: inline-block;
    font-size: 14px;
    margin-top: 32px;
  }
}
</style>
