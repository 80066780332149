<template>
  <ValidationObserver v-slot="{ handleSubmit, invalid, errors, reset }">
    <form
      ref="questionnaireFormRef"
      class="questionnaire-form"
      @submit.prevent="handleSubmit(onSubmitForm)"
      @reset.prevent="reset"
    >
      <fieldset class="questionnaire-form__block">
        <h3 class="questionnaire-form__block-title">
          Ваша спеціальність
        </h3>

        <ValidationProvider rules="required:Оберіть спеціальність" v-slot="{ errors }">
          <small v-if="errors[0]" class="questionnaire-form__error">
            <img src="@/assets/icons/infocircle.svg" alt="info circle">
            {{ errors[0] }}
          </small>

          <div class="questionnaire-form__radio-buttons">
            <MyRadioButton v-for="specialty in specialties" :key="specialty"
              :id="'questionnaire-specialty-' + specialty" group="specialty" :ownValue="specialty" :label="specialty"
              v-model="formData.survey.doctor.specialty">
              {{ specialty }}
            </MyRadioButton>
          </div>
        </ValidationProvider>
      </fieldset>

      <fieldset class="questionnaire-form__block">
        <h3 class="questionnaire-form__block-title">
          Ваші дані
        </h3>

        <ValidationProvider rules="required:Вкажіть Ваше прізвище|minmax:2,50" v-slot="{ errors }">
          <MyInput v-model="formData.survey.doctor.lastName" id="questionnaire-last-name" label="Прізвище"
            placeholder="Прізвище" required :error="errors.length > 0" :errorMessage="errors[0]" />
        </ValidationProvider>

        <ValidationProvider rules="required:Вкажіть Ваше імʼя|minmax:2,50" v-slot="{ errors }">
          <MyInput v-model="formData.survey.doctor.firstName" id="questionnaire-first-name" label="Ім'я" required
            placeholder="Ім'я" :error="errors.length > 0" :errorMessage="errors[0]" />
        </ValidationProvider>

        <ValidationProvider rules="required:Вкажіть Ваше по батькові|minmax:2,50" v-slot="{ errors }">
          <MyInput v-model="formData.survey.doctor.middleName" id="questionnaire-middle-name" label="По батькові"
            required placeholder="По батькові" :error="errors.length > 0" :errorMessage="errors[0]" />
        </ValidationProvider>

        <ValidationProvider rules="required:Вкажіть Ваш номер телефону|phone" v-slot="{ errors }">
          <MyInput v-model="formData.survey.doctor.phoneNumber" id="questionnaire-phone-number" label="Номер телефону"
            required placeholder="Номер телефону" type="phone" :error="errors.length > 0" :errorMessage="errors[0]" />
        </ValidationProvider>

        <ValidationProvider rules="required:Вкажіть Ваш населений пункт|minmax:2,50" v-slot="{ errors }">
          <MyInput v-model="formData.survey.doctor.location" id="questionnaire-location" label="Населений пункт"
            required placeholder="Населений пункт" :error="errors.length > 0" :errorMessage="errors[0]" />
        </ValidationProvider>
      </fieldset>

      <fieldset class="questionnaire-form__block">
        <h3 class="questionnaire-form__block-title">
          Дані пацієнта
        </h3>

        <ValidationProvider rules="required:Оберіть стать" v-slot="{ errors }">
          <small v-if="errors[0]" class="questionnaire-form__error">
            <img src="@/assets/icons/infocircle.svg" alt="info circle">
            {{ errors[0] }}
          </small>

          <div class="questionnaire-form__radio-buttons">
            <MyRadioButton id="questionnaire-specialty-male" group="sex" ownValue="Чоловік" label="Чоловік"
              v-model="formData.survey.patient.gender">
              Чоловік
            </MyRadioButton>

            <MyRadioButton id="questionnaire-specialty-female" group="sex" ownValue="Жінка" label="Жінка"
              v-model="formData.survey.patient.gender">
              Жінка
            </MyRadioButton>
          </div>
        </ValidationProvider>

        <ValidationProvider rules="required:Введіть вік пацієнта|minmaxage:30,80" v-slot="{ errors }">
          <MyInput v-model="formData.survey.patient.age" id="questionnaire-patient-age" label="Вік" required
            placeholder="Вік" type="number" :error="errors.length > 0" :errorMessage="errors[0]" />
        </ValidationProvider>

        <ValidationProvider rules="required:Заповніть поле з діагнозом" v-slot="{ errors }">
          <MyTextarea v-model="formData.survey.patient.diagnosis" id="questionnaire-diagnosis"
            label="Повний діагноз (основне захворювання, супутні захворювання та ускладнення)"
            placeholder="Повний діагноз (основне захворювання, супутні захворювання та ускладнення)" required
            :error="errors.length > 0" :errorMessage="errors[0]"></MyTextarea>
        </ValidationProvider>

        <ValidationProvider rules="required:Заповніть поле з анамнезом" v-slot="{ errors }">
          <MyTextarea v-model="formData.survey.patient.anamnesis" id="questionnaire-anamnesis"
            label="Короткий анамнез, скарги, перебіг хвороби, проведене лікування"
            placeholder="Короткий анамнез, скарги, перебіг хвороби, проведене лікування" required
            :error="errors.length > 0" :errorMessage="errors[0]"></MyTextarea>
        </ValidationProvider>

        <ValidationProvider rules="required:Вкажіть стан пацієнта" v-slot="{ errors }">
          <MyTextarea v-model="formData.survey.patient.conditionAtAdmission" id="questionnaire-condition-at-admission"
            label="Стан при направленні" placeholder="Стан при направленні" required :error="errors.length > 0"
            :errorMessage="errors[0]"></MyTextarea>
        </ValidationProvider>

        <ValidationProvider rules="required:Заповніть поле з діагностичними досліжєеннями" v-slot="{ errors }">
          <MyTextarea v-model="formData.survey.patient.diagnosticStudies" id="questionnaire-diagnostic-studies"
            label="Діагностичні дослідження" placeholder="Діагностичні дослідження" required :error="errors.length > 0"
            :errorMessage="errors[0]"></MyTextarea>
        </ValidationProvider>

        <ValidationProvider rules="required:Вкажіть рекомендації для пацієнта" v-slot="{ errors }">
          <MyTextarea v-model="formData.survey.patient.treatmentAndWorkRecommendations"
            id="questionnaire-diagnostic-treatment-and-work-recommendations"
            label="Призначене лікування й трудові рекомендації"
            placeholder="Призначене лікування й трудові рекомендації" required :error="errors.length > 0"
            :errorMessage="errors[0]"></MyTextarea>
        </ValidationProvider>

        <MyTextarea v-model="formData.survey.patient.additionalInformation"
          id="questionnaire-diagnostic-additional-information" label="Додаткова інформація (якщо є)"
          placeholder="Додаткова інформація (якщо є)"></MyTextarea>
      </fieldset>

      <fieldset class="questionnaire-form__block">
        <h3 class="questionnaire-form__block-title">
          Ваше запитання
        </h3>

        <MyTextarea v-model="formData.survey.patient.questionsFromDoctor" id="questionnaire-questions-from-doctor"
          label="Ваше запитання" :placeholder="`Наприклад:
      • які найсучасніші рекомендації лікування АГ?
      • які лікарські взаємодії потрібно врахувати при застосуванні Раміприлу-Тева?`"
          class="questionnaire-form__large-textarea"></MyTextarea>
      </fieldset>

      <fieldset class="questionnaire-form__block">
        <small v-if="errors['questionnaire-agreement-publish-data']?.length ||
          errors['questionnaire-agreement-process-personal-data']?.length" class="questionnaire-form__error">
          <img src="@/assets/icons/infocircle.svg" alt="info circle">

          <template
            v-if="errors['questionnaire-agreement-publish-data'].length && errors['questionnaire-agreement-process-personal-data'].length">
            На жаль, ми не зможемо прийняти анкету без Вашої згоди на публікацію медичного випадку та обробку
            персональних даних
          </template>

          <template v-else-if="errors['questionnaire-agreement-publish-data'].length">
            {{ errors['questionnaire-agreement-publish-data'][0] }}
          </template>

          <template v-else-if="errors['questionnaire-agreement-process-personal-data'].length">
            {{ errors['questionnaire-agreement-process-personal-data'][0] }}
          </template>
        </small>

        <ValidationProvider
          vid="questionnaire-agreement-publish-data"
          rules="checked:На жаль, ми не зможемо прийняти анкету без Вашої згоди на публікацію медичного випадку на сайті"
        >
          <MyCheckbox v-model="formData.agreement.isAgreedToPublishData" id="questionnaire-agreement-publish-data"
            label="Даю згоду на публікацію даного медичного випадку на сайті cbg.teva.ua" required></MyCheckbox>
        </ValidationProvider>

        <ValidationProvider
          vid="questionnaire-agreement-process-personal-data"
          rules="checked:На жаль, ми не зможемо прийняти анкету без Вашої згоди на обробку персональних даних"
        >
          <MyCheckbox v-model="formData.agreement.isAgreedToProcessPersonalData"
            id="questionnaire-agreement-process-personal-data" required>
            <span>
              Даю згоду на обробку
              <span class="questionnaire-form__link" @click.prevent="openAgreementDataProcessingModal">
                персональних даних
              </span>
              згідно з
              <a
                href="https://www.tevapharm.com/our-company/corporate-governance/data-privacy/"
                class="questionnaire-form__link"
                target="_blank"
              >
                політикою конфіденційності
              </a>
            </span>
          </MyCheckbox>
        </ValidationProvider>


        <MyCheckbox v-model="formData.agreement.isAgreedToReceiveInformation"
          id="questionnaire-agreement-receive-information"
          label="Даю згоду на звернення до мене представниками Тева у разі необхідності уточнень щодо анкети">
        </MyCheckbox>
      </fieldset>

      <div class="questionnaire-form__block questionnaire-form__block--submit">
        <div v-if="invalid" class="questionnaire-form__error-banner">
          <img src="@/assets/icons/infocircle_thin.svg" alt="info circle">

          Будь ласка, заповніть усі необхідні поля, щоб відправити анкету
        </div>

        <QuestionnaireButton view="primary">
          Відправити анкету
        </QuestionnaireButton>
      </div>
    </form>

    <QuestionnaireAgreementDataProcessingModal v-if="isModalOpen.agreementDataProcessing"
      @close="closeAgreementDataProcessingModal" />
    <QuestionnaireThankYouModal v-if="isModalOpen.thankYou" @close="closeThankYouModal" />
    <QuestionnaireErrorModal v-if="isModalOpen.errorModal" @close="closeErrorModal" />
  </ValidationObserver>
</template>

<script>
  import MyRadioButton from '@/components/form-controls/MyRadioButton.vue';
  import MyInput from '@/components/form-controls/MyInput.vue';
  import MyTextarea from '@/components/form-controls/MyTextarea.vue';
  import MyCheckbox from '@/components/form-controls/MyCheckbox.vue';
  import QuestionnaireButton from '@/views/questionnaire/QuestionnaireButton.vue';
  import QuestionnaireAgreementDataProcessingModal from './QuestionnaireAgreementDataProcessingModal.vue';
  import QuestionnaireThankYouModal from './QuestionnaireThankYouModal.vue';
  import QuestionnaireErrorModal from './QuestionnaireErrorModal.vue';

  import axios from 'axios';
  import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
  import { nextTick } from 'vue';

  extend('required', {
    validate(value, args) {
      return value.length > 0;
    },
    computesRequired: true,
    params: ['message'],
    message: '{message}',
  });

  extend('minmaxage', {
    validate(value, { min, max }) {
      return parseInt(value) >= parseInt(min) && parseInt(value) <= parseInt(max);
    },
    params: ['min', 'max'],
    message: 'Введіть вік пацієнта в межах від {min} до {max} років',
  });

  extend('checked', {
    validate(value, args) {
      return value;
    },
    params: ['message'],
    message: '{message}',
  });

  extend('phone', {
    validate(value) {
      const phoneRegex = /^\+38 \(0\d{2}\) \d{3} \d{2} \d{2}$/;

      return phoneRegex.test(value);
    },
    // message: 'Введіть коректний номер телефону',
    message: 'Перевірте кількість цифр у номері телефону',
  });

  extend('minmax', {
    validate(value, { min, max }) {
      return value.length >= parseInt(min) && value.length <= parseInt(max);
    },
    params: ['min', 'max'],
    message: 'Введіть від {min} до {max} символів',
  });

  export default {
    name: 'QuestionnaireForm',

    components: {
      MyRadioButton,
      MyInput,
      MyTextarea,
      MyCheckbox,
      QuestionnaireButton,
      QuestionnaireAgreementDataProcessingModal,
      QuestionnaireThankYouModal,
      QuestionnaireErrorModal,
      ValidationObserver,
      ValidationProvider,
    },

    data() {
      return {
        formData: {
          survey: {
            doctor: {
              specialty: '',
              lastName: '',
              firstName: '',
              middleName: '',
              phoneNumber: '',
              location: '',
            },
            patient: {
              gender: '',
              age: '',
              diagnosis: '',
              anamnesis: '',
              conditionAtAdmission: '',
              diagnosticStudies: '',
              treatmentAndWorkRecommendations: '',
              additionalInformation: '',
              questionsFromDoctor: '',
            },
          },
          agreement: {
            isAgreedToPublishData: false,
            isAgreedToProcessPersonalData: false,
            isAgreedToReceiveInformation: false,
          },
        },

        specialties: [
          'Сімейний лікар',
          'Терапевт',
        ],

        isModalOpen: {
          agreementDataProcessing: false,
          thankYou: false,
          errorModal: false,
        },
      };
    },

    methods: {
      async onSubmitForm() {
        try {
          await this.sendSurveyData();

          window.scrollTo({
            top: 0,
            behavior: 'smooth',
          });

          this.resetForm();
          this.openThankYouModal();

            gtag('event', 'click', {'event_category': 'форма відправлена успішно'})
        } catch (error) {
          console.log('Error on Submit: ', error);
          this.openErrorModal();
          gtag('event', 'click', {'event_category': 'форма не відправлена'})
        }
      },

      resetForm() {
        this.formData = {
          survey: {
            doctor: {
              specialty: '',
              lastName: '',
              firstName: '',
              middleName: '',
              phoneNumber: '',
              location: '',
            },
            patient: {
              gender: '',
              age: '',
              diagnosis: '',
              anamnesis: '',
              conditionAtAdmission: '',
              diagnosticStudies: '',
              treatmentAndWorkRecommendations: '',
              additionalInformation: '',
              questionsFromDoctor: '',
            },
          },
          agreement: {
            isAgreedToPublishData: false,
            isAgreedToProcessPersonalData: false,
            isAgreedToReceiveInformation: false,
          },
        };

        nextTick(() => {
          this.$refs.questionnaireFormRef.reset();
        });
      },

      async sendSurveyData() {
        const { doctor, patient } = this.formData.survey;

        try {
          const token = (await axios.get(`${this.$root.globalUrl}/api/survey/generate-token`)).data.token;

          const response = await axios.post(
            `${this.$root.globalUrl}/api/survey`,
            {
              survey: {
                doctor: {
                  specialty: doctor.specialty,
                  last_name: doctor.lastName,
                  first_name: doctor.firstName,
                  middle_name: doctor.middleName,
                  phone_number: doctor.phoneNumber.replace(/[\s()\-]+/g, ''),
                  location: doctor.location,
                },
                patient: {
                  gender: patient.gender,
                  age: parseInt(patient.age, 10),
                  diagnosis: patient.diagnosis,
                  anamnesis: patient.anamnesis,
                  condition_at_admission: patient.conditionAtAdmission,
                  diagnostic_studies: patient.diagnosticStudies,
                  treatment_and_work_recommendations: patient.treatmentAndWorkRecommendations,
                  additional_information: patient.additionalInformation,
                  questions_from_doctor: patient.questionsFromDoctor,
                },
              },
              consent_to_contact: this.formData.agreement.isAgreedToReceiveInformation,
            },
            { headers: { Authorization: 'Bearer ' + token }},
          );
        } catch (error) {
          console.log('Error on Send: ', error);
          throw error;
        }
      },

      openAgreementDataProcessingModal() {
        this.isModalOpen.agreementDataProcessing = true;
      },

      closeAgreementDataProcessingModal() {
        this.isModalOpen.agreementDataProcessing = false;
      },

      openThankYouModal() {
        this.isModalOpen.thankYou = true;
      },

      closeThankYouModal() {
        this.isModalOpen.thankYou = false;
      },

      openErrorModal() {
        this.isModalOpen.errorModal = true;
      },

      closeErrorModal() {
        this.isModalOpen.errorModal = false;
      },
    },
  };
</script>

<style lang="scss">
  @import "../../assets/style/fonts.css";
  @import "../../assets/style/vars";
  @import "../../assets/style/mixins";

  .questionnaire-form {
    &__block {
      display: flex;
      flex-direction: column;
      gap: 8px;
      margin-bottom: 64px;

      @include phone {
        margin-bottom: 48px;
      }

      &--submit {
        align-items: center;
        margin-bottom: 0;
      }
    }

    &__block-title {
      font-family: $EB;
      color: $grey;
      font-size: 18px;
      margin-bottom: 12px;
    }

    &__radio-buttons {
      display: flex;
      gap: 40px;
      margin-bottom: 10px;
    }

    &__error {
      display: flex;
      align-items: flex-start;
      gap: 4px;

      font-family: $R;
      color: $red;
      font-size: 14px;

      margin-bottom: 6px;
    }

    &__error-banner {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 16px;
      padding: 24px 16px;
      background-color: #fff2f2;
      border: 1.5px solid $red;
      font-family: $R;
      color: $grey;
      font-size: 14px;
      margin-bottom: 16px;

      img {
        height: 32px;
        width: 32px;
      }
    }

    &__large-textarea .my-textarea__textarea {
      min-height: 160px;
    }

    &__link {
      color: $green;
      cursor: pointer;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }
</style>
