<template>
  <MyModal
    class="questionnaire-error-modal"
    :withCloseButton="false"
    @close="closeErrorModal"
  >
    <h3>
      Сталася помилка!
    </h3>

    <p>
      Спробуйте відправити анкету пізніше.
    </p>

    <QuestionnaireButton class="questionnaire-thank-you-modal__button" @click.native="closeErrorModal">
      Добре
    </QuestionnaireButton>
  </MyModal>

</template>

<script>
import MyModal from '@/components/shared/MyModal.vue';
import QuestionnaireButton from './QuestionnaireButton.vue';

export default {
  name: 'QuestionnaireErrorModal',

  components: {
    MyModal,
    QuestionnaireButton,
  },

  methods: {
    closeErrorModal() {
      this.$emit('close');
    },
  }
};
</script>

<style lang="scss">
.questionnaire-error-modal {
  p {
    font-size: 14px;
    text-align: center;
    margin-bottom: 18px;
  }

  &__button {
    width: 240px;
    margin: 0 auto;
  }
}
</style>
