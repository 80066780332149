<template>
  <div class="questionnaire">
    <h1 class="questionnaire__title">
      Анкета "Клінічні випадки"
    </h1>

    <QuestionnaireButton
      view="secondary"
      class="questionnaire__anchor-button"
      @click.native="scrollToForm"
    >
      <template #icon>
        <img src="@/assets/icons/arrow_down.svg" alt="arrow down">
      </template>
      Заповнити анкету
    </QuestionnaireButton>

    <div class="questionnaire__container">
      <div class="questionnaire__content questionnaire__content--left">
        <p class="questionnaire__description">
          Компанія Тева в Україні створила цей <span class="questionnaire__link" @click="openMoreAboutResourceModal">ресурс</span>, щоб допомогти з джерелами інформації, які регулюють алгоритми надання медичної допомоги пацієнтам з серцево-судинними захворюваннями, на базі практичних клінічних випадків, які виникли у сімейного лікаря в минулому та/або отримання відповідей на запитання щодо застосування ЛЗ компанії Тева в описаному випадку.
          <br>
          <br>
          Поділіться своїм клінічним випадком, заповнивши анкету — ми можемо опублікувати його на сайті. Просимо не зазначати в анкеті персональні дані, за якими можна ідентифікувати пацієнтів.
        </p>

        <QuestionnaireForm ref="questionnaireFormRef" class="questionnaire__form" @submit="onSubmitForm"/>

        <div class="questionnaire__shortcuts">
          <span v-for="shortcut in shortcuts" :key="shortcut" class="questionnaire__shortcuts-item">
            {{ shortcut }}
          </span>
        </div>

        <span class="questionnaire__code">
          CBG-UA-01656 exp.date 22.10.2026
        </span>
      </div>

      <div class="questionnaire__content questionnaire__content--right">
        <img
          src="@/assets/img/questionnaire/banner.png"
          class="questionnaire__banner questionnaire__banner--desktop"
          alt="banner"
        >
        <img
          src="@/assets/img/questionnaire/banner-mobile.png"
          class="questionnaire__banner questionnaire__banner--mobile"
          alt="banner"
        >
      </div>
    </div>

    <QuestionnaireMoreAboutResourceModal v-if="isModalOpen.moreAboutResource" @close="closeMoreAboutResourceModal"/>
  </div>
</template>

<script>
import QuestionnaireForm from './QuestionnaireForm.vue';
import QuestionnaireButton from './QuestionnaireButton.vue';
import QuestionnaireMoreAboutResourceModal from './QuestionnaireMoreAboutResourceModal.vue';

export default {
  name: 'Questionnaire',

  components: {
    QuestionnaireForm,
    QuestionnaireButton,
    QuestionnaireMoreAboutResourceModal,
  },

  data() {
    return {
      isModalOpen: {
        moreAboutResource: false,
      },
      shortcuts: [
        'АТ — артеріальна гіпертензія',
        'ЛЗ — лікарський засіб',
        'СН — серцева недостатність',
        'ХХН — хронічна хвороба нирок',
      ],
    };
  },

  created() {
    document.title = 'Анкета "Клінічні випадки"';
  },

  methods: {
    scrollToForm() {
      const element = this.$refs.questionnaireFormRef.$el;

      if (element) {
        const elementPosition = element.getBoundingClientRect().top + window.scrollY;

        window.scrollTo({
          top: elementPosition - 100,
          behavior: 'smooth',
        });
      }
    },

    onSubmitForm() {
      this.openThankYouModal();
    },

    openMoreAboutResourceModal() {
      this.isModalOpen.moreAboutResource = true;
    },

    closeMoreAboutResourceModal() {
      this.isModalOpen.moreAboutResource = false;
    },
  },
};
</script>

<style lang="scss">
  @import "../../assets/style/fonts.css";
  @import "../../assets/style/vars";
  @import "../../assets/style/mixins";

  .questionnaire {
    min-height: calc((var(--vh, 1vh) * 100) - var(--height_content));
    max-width: 1270px;
    padding-top: 60px;
    padding-bottom: 40px;
    margin: 0 auto;

    &__title {
      text-align: center;
      color: $grey;
      font-size: 48px;
      font-weight: 800;
      font-family: $EB;
      margin: 0 20px 54px;

      @include tablet {
        font-size: 36px;
      }

      @include phone {
        font-size: 24px;
        margin-bottom: 32px;
      }
    }

    &__anchor-button {
      display: none;
      margin: 0 auto 32px;

      @include phone {
        display: flex;
      }
    }

    &__container {
      display: flex;
      justify-content: space-between;
      gap: 105px;

      @include tablet {
        gap: 18px;
      }

      @include phone {
        align-items: center;
        flex-direction: column-reverse;
        gap: 32px;
      }
    }

    &__content {
      flex: 1;
      margin: 0 20px;

      &--left {
        margin-left: 20px;
      }

      &--right {
        max-width: 502px;
        margin-right: 20px;

        @include phone {
          max-width: 100%;
          width: calc(100% - 40px);
        }
      }
    }

    &__description {
      font-size: 18px;
      line-height: 26px !important;
      color: $grey;
      font-family: $R;
      margin-bottom: 64px;

      @include tablet {
        font-size: 16px;
        line-height: 22px !important;
      }

      @include phone {
        line-height: normal !important;
        margin-bottom: 48px;
      }
    }

    &__form {
      display: block;
      margin-bottom: 38px;

      @include phone {
        margin-bottom: 72px;
      }
    }

    &__shortcuts {
      display: flex;
      flex-direction: column;
      gap: 2px;

      font-family: $R;
      color: $grey;
      font-size: 14px;
      margin-bottom: 24px;
    }

    &__code {
      opacity: 0.4;
      font-family: $R;
      font-size: 14px;
    }

    &__banner {
      position: sticky;
      top: 100px;
      width: 100%;
      box-shadow: 0px 20px 50px -15px rgba(0, 0, 0, 0.30);

      &--desktop {
        display: block;
      }

      &--mobile {
        display: none;
      }

      @include phone {
        position: static;
        object-fit: cover;
        object-position: top;
        max-width: 100%;
        width: 100%;

        &--desktop {
          display: none;
        }

        &--mobile {
          display: block;
        }
      }
    }

    &__link {
      color: $green;
      text-decoration: underline;
      cursor: pointer;

      &:hover {
        text-decoration: none;
      }
    }
  }
</style>
