<template>
  <button class="questionnaire-button" :class="{
    'questionnaire-button--primary': view === 'primary',
    'questionnaire-button--secondary': view === 'secondary',
  }">
    <slot name="icon"></slot>
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: 'QuestionnaireButton',

  props: {
    view: {
      type: String,
      default: 'primary',
    },
  },
};
</script>

<style lang="scss">
@import "../../assets/style/fonts.css";
@import "../../assets/style/vars";
@import "../../assets/style/mixins";

.questionnaire-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: min-content;
  padding: 20px 58px;
  font-family: $B;
  color: #fff;
  white-space: nowrap;
  border: none;
  border-radius: 100px;
  cursor: pointer;
  transition: background-color 0.1s;

  &--primary {
    background-color: $green;

    &:hover {
      background-color: lighten($green, 5%);
    }

    &:active {
      background-color: darken($green, 5%);
    }
  }

  &--secondary {
    color: $teal;
    background-color: #3C7E791A;

    &:hover {
      background-color: darken(#3C7E791A, 10%);
    }

    &:active {
      background-color: darken(#3C7E791A, 20%);
    }
  }

  @include phone {
    padding: 19px 40px;
  }
}
</style>
