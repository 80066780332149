<template>
  <div class="my-checkbox">
    <input
      type="checkbox"
      :id="id"
      :value="value"
      :checked="value"
      class="my-checkbox__input"
      @change="updateValue"
    >
 
    <label :for="id" class="my-checkbox__label">
      <div class="my-checkbox__custom-input" />
      <span>
        <slot>
          {{ label }}
        </slot>
        <span class="my-checkbox__required-star" v-if="required">*</span>
      </span>
    </label>
  </div>
</template>

<script>
export default {
  name: 'MyCheckbox',

  props: {
    value: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    updateValue() {
      this.$emit('input', !this.value);
    },
  },
};
</script>

<style lang="scss">
@import "../../assets/style/fonts.css";
@import "../../assets/style/vars";
@import "../../assets/style/mixins";

.my-checkbox {
  &__input {
    display: none;
  }

  &__custom-input {
    width: 24px;
    height: 24px;
    min-width: 24px;
    border: 1px solid #d6d6d6;

    transition: border-color 0.3s;
  }

  &__input:checked + &__label &__custom-input {
    background-image: url('../../assets/icons/check.svg');
    background-repeat: no-repeat;
    background-position: center;
    border: 2px solid transparent;
    background-color: $green;
  }

  &__label {
    display: flex;
    gap: 12px;

    font-family: $R;
    color: $grey;
    font-size: 16px;
    font-weight: 400;
    cursor: pointer;

    &:hover &__custom-input {
      border: 2px solid $green;
    }
  }

  &__required-star {
    color: $red;
  }
}
</style>
