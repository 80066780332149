<template>
  <div class="my-textarea">
    <label ref="myTextareaLabelRef" v-if="label && value" :for="id" class="my-textarea__label">
      {{ label }}
      <span v-if="required">
        *
      </span>
    </label>

    <textarea
      ref="myTextareaRef"
      class="my-textarea__textarea"
      :class="{
        'my-textarea__textarea--filled': value,
        'my-textarea__textarea--error': error,
      }"
      :id="id"
      :value="value"
      :placeholder="placeholder + (required ? ' *' : '')"
      @input="onInput"
    />

    <small v-if="error && errorMessage" class="my-textarea__error">
      <img src="@/assets/icons/infocircle.svg" alt="info circle">
      {{ errorMessage }}
    </small>
  </div>
</template>

<script>
import { nextTick } from 'vue';
import { mapGetters } from 'vuex';

export default {
  name: 'MyTextarea',

  props: {
    value: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: '',
    },
  },

  computed: {
    ...mapGetters([
      'getWindowWidth',
    ]),
  },

  methods: {
    onInput(event) {
      this.$emit('input', event.target.value.trim());
    },

    changeTextareaLabelPadding() {
      const labelRef = this.$refs.myTextareaLabelRef;
      const textareaRef = this.$refs.myTextareaRef;

      if (labelRef) {
        const labelHeight = labelRef.getBoundingClientRect().height;
        textareaRef.style.paddingTop = `${labelHeight}px`;
      } else {
        textareaRef.style.paddingTop = '20px';
      }
    }
  },

  watch: {
    getWindowWidth() {
      this.changeTextareaLabelPadding();
    },

    value() {
      nextTick(() => {
        this.changeTextareaLabelPadding();
      });
    },
  },

};
</script>

<style lang="scss">
@import "../../assets/style/fonts.css";
@import "../../assets/style/vars";
@import "../../assets/style/mixins";

.my-textarea {
  position: relative;

  &__label {
    position: absolute;
    top: 1px;
    left: 1px;
    right: 20px;
    padding: 10px 0px 7px 20px;
    background-color: #fff;
    font-family: $SB;
    font-size: 13px;
    font-weight: 600;

    span {
      color: $red;
    }
  }

  &__textarea {
    width: 100%;
    min-height: 100px;
    height: min-content;
    padding: 20px;
    border: 1px solid #d6d6d6;
    color: $grey;
    font-size: 16px;
    outline: none;
    resize: vertical;
    transition: border-color .3s;

    &:hover {
      border-color: $green;
    }

    &:focus {
      border-color: $green;
      outline: 1px solid $green;
    }

    &::placeholder {
      font-size: 16px;
    }

    &--error,
    &--error:hover,
    &--error:focus {
      border-color: $red;
      outline: 1px solid $red;
    }
  }

  &__error {
    display: flex;
    align-items: flex-start;
    gap: 4px;
    font-family: $R;
    color: $red;
    font-size: 14px;
    margin-top: 4px;
  }
}
</style>
